<template>
  <main>
    <div class="holder">
      <a href="javascript:" @click="goBack" id="backlink">{{ $t('common.back') }}</a>

      <section class="form full">
        <h1>{{ $t('delivery.cdekDelivery') }}</h1>

        <form action="#" method="post">

          <div class="section" id="location">
            <h2>{{ $t('delivery.cdekAddress') }}</h2>

            <div id="map" class="field" v-if="dto.pointId">
              <GmapMap
                       :center="previewMapCenter"
                       :zoom="15"
                       map-type-id="terrain"
                       style="width: 100%; height: 100%"
                       ref="mapRef"
                       :options="{
                         zoomControl: false,
                         mapTypeControl: false,
                         scaleControl: false,
                         streetViewControl: false,
                         rotateControl: false,
                         fullscreenControl: false,
                         disableDefaultUi: false
                       }"
                       @click="onMapClicked"
              >
                <GmapMarker v-if="!!(google)"
                            :position="previewMapCenter"
                            :draggable="false"
                            :icon="{
                              strokeColor: '#FFFFFF',
                              strokeOpacity: 1,
                              strokeWeight: 3,
                              fillColor: '#00D9B2',
                              fillOpacity: 1,
                              path: google.maps.SymbolPath.CIRCLE,
                              scale: 15,
                              anchor: new google.maps.Point(0, 0)
                            }"
                >
                </GmapMarker>
              </GmapMap>
            </div>
            <div class="field" v-else @click="onMapClicked"><span>{{ $t('add-item.add-location') }}</span></div>
          </div>


          <div class="section">
            <h2>{{ $t('delivery.phoneNumber') }}</h2>

            <div>
              <input type="tel" v-model="dto.phone" name="phone" placeholder="+79000000000" maxlength="12">
            </div>
          </div>

          <div class="section">
            <h2>{{ $t('delivery.fullName') }}</h2>
            <input type="text" v-model="dto.name" name="name" :placeholder="$t('delivery.fullName')">
          </div>

          <div class="section" v-if="!isRecipient">
            <h2>{{ $t('delivery.approxWeight') }}</h2>
            <input type="number" v-model="dto.dimensions.weight" name="weight" placeholder="0">
          </div>


          <div class="section" id="duration" v-if="!isRecipient">
            <h2>{{ $t('delivery.boxSize') }}</h2>
            <ul>
              <li v-for="sizeType in sizeTypes" :class="{ act: dto.dimensions.size_type === sizeType.val }"
                  :data-val="sizeType.val" @click="sizeTypeSelected(sizeType)">
                {{ sizeType.title ? sizeType.title : (sizeType.val + ', ' + sizeType.L + "x" + sizeType.W + "x" + sizeType.H) }}
              </li>
            </ul>
          </div>

          <div :class="{ section: true, hidden: dto.dimensions.size_type !== sizeTypeOther}">
            <h2>{{ $t('delivery.width') }}</h2>
            <input v-model="W" type="number" name="width" placeholder="0">
          </div>
          <div :class="{ section: true, hidden: dto.dimensions.size_type !== sizeTypeOther}">
            <h2>{{ $t('delivery.height') }}</h2>
            <input v-model="H" type="number" name="height" placeholder="0">
          </div>
          <div :class="{ section: true, hidden: dto.dimensions.size_type !== sizeTypeOther}">
            <h2>{{ $t('delivery.depth') }}</h2>
            <input v-model="L" type="number" name="deep" placeholder="0">
          </div>

          <button name="submit_btn" :disabled="loading || !chatDTO" :class="{btn: true, loading: loading }" @click="onSubmit" onclick="return false;">{{ $t('delivery.submitDelivery') }}</button>
        </form>
      </section>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { gmapApi } from 'gmap-vue';
import {eventBus} from "@/main";
import modal from '../assets/js/init/modal';

export default {
  name: 'Delivery',
  title: 'Delivery',
  data() {
    return {
      dto: {
        name: "",
        phone: "+7",
        chatId: null,
        dimensions: {
          weight: 0,
          size_type: "XS",
          size_l_w_h: "",
        },
        pointId: null,
      },

      sizeTypeOther: "Other",
      previewMapCenter: {},

      sizeTypes: [],
      W: 0,
      H: 0,
      L: 0,

      loading: false,
      isRecipient: true, // если пользователь является получателем, то он заполняет меньшее количество полей.
      chatDTO: null,
    }
  },
  computed: {
    ...mapGetters(['user']),
    google: gmapApi,
  },

  created() {
    this.sizeTypes = [
      {
        val: "XS",
        W: 12,
        H: 9,
        L: 17,
        weight: 0.5,
      },
      {
        val: "S",
        W: 19,
        H: 10,
        L: 23,
        weight: 2,
      },
      {
        val: "M",
        W: 25,
        H: 15,
        L: 33,
        weight: 5,
      },
      {
        val: "L",
        W: 25,
        H: 38,
        L: 31,
        weight: 12,
      },
      {
        val: "XL",
        W: 35,
        H: 30,
        L: 60,
        weight: 18,
      },
      {
        title: this.$t('delivery.other'),
        val: this.sizeTypeOther,
        W: 0,
        H: 0,
        L: 0,
        weight: 0,
      }
    ];
    this.sizeTypeSelected(this.sizeTypes[0]);
  },

  watch: {
    user: {
      handler(newVal) {
        // если пользователь, наконец, загрузился (уже после chatDTO)
        if (this.chatDTO) {
          this.isRecipient = this.chatDTO.recipient.id == newVal.id;
          //this.isRecipient = false;
        }
      },
      deep: true
    }
  },

  mounted() {
    eventBus.$on('cdek-delivery-point-chosen', (data) => {
      this.previewMapCenter = data.item.latlng;
      this.dto.pointId = data.pointId;
    });

    let params = {
      id: this.$route.params.id,
    };

    this.loadChatById(params).then((response) => {
      this.chatDTO = response.data;
      if (this.chatDTO.delivery === false) {
        // доставка уже произведена
        this.showToast(this.$t('delivery.deliveryAlreadyProcessed'), "success");
        this.$router.push({
          name: 'chat',
          params: { id: this.$route.params.id }
        });
        return;
      }

      // если пользователь уже загрузился (не пустота в id)
      if (this.user.id) {
        this.isRecipient = response.data.recipient.id == this.user.id;
        //this.isRecipient = false;
      }
    });
  },

  methods: {
    ...mapActions([
      "deliveryRequest", 'loadChatById'
    ]),

    goToMain() {
      this.$router.push({ name: 'objects' });
    },

    isValidInt(val) {
      let intVal = parseInt(val);
      return !isNaN(intVal);
    },

    onSubmit() {

      let goodsId = this.chatDTO.goodsId;
      if (!this.isRecipient) {

        // check every number
        if (!this.isValidInt(this.W) || this.W <= 0) {
          this.showToast(this.$t('delivery.invalidWidth'), 'error');
          return;
        }
        if (!this.isValidInt(this.L) || this.L <= 0) {
          this.showToast(this.$t('delivery.invalidDepth'), 'error');
          return;
        }
        if (!this.isValidInt(this.H) || this.H <= 0) {
          this.showToast(this.$t('delivery.invalidHeight'), 'error');
          return;
        }

        this.dto.dimensions.size_l_w_h = this.L + "X" + this.W + "X" + this.H;
      } else {
        this.dto.dimensions.size_l_w_h = null;
        this.dto.dimensions.size_type = null;
      }

      if (this.dto.pointId == null) {
        this.showToast(this.$t('delivery.addressNotSelected'), 'error');
        return;
      }

      if (!this.dto.name) {
        this.showToast(this.$t('delivery.fullNameRequired'), 'error');
        return;
      }

      if (!this.dto.phone) {
        this.showToast(this.$t('delivery.phoneNumberRequired'), 'error');
        return;
      }

      if (!this.isRecipient && (!this.isValidInt(this.dto.dimensions.weight) || this.dto.dimensions.weight <= 0)) {
        this.showToast(this.$t('delivery.approxWeightRequired'), 'error');
        return;
      }

      this.dto.chatId = this.$route.params.id;
      let payload = {
        goodsId: goodsId,
        dto: {...this.dto},
      }

      this.loading = true;
      this.deliveryRequest(payload).then((response) => {
        this.showToast(this.$t('delivery.deliverySuccess'), "success");
        this.$router.push({
          name: 'chat',
          params: { id: this.$route.params.id }
        });
      }).catch((response) => {
        this.showToast(this.$t('delivery.deliveryError'), 'error');
      }
      ).finally(() => {
        this.loading = false;
      });
    },

    onMapClicked() {
      modal.openModal("modal-choose-cdek-delivery-point");
    },

    sizeTypeSelected(type) {
      this.dto.dimensions.size_type = type.val;
      this.W = type.W;
      this.L = type.L;
      this.H = type.H;
      this.dto.dimensions.weight = type.weight;
    },

    goBack() {
      this.$router.push({
        name: 'chat',
        params: { id: this.$route.params.id }
      });
    },
  }
};
</script>